<template>
  <div class="primary lighten-1 fill-height">
    <v-app-bar absolute color="transparent" flat>
      <v-spacer />
      <v-btn icon :to="{ name: 'savedraft' }">
        <v-badge
          color="secondary"
          dot
          overlap
          bordered
          v-if="numberOfdraft > 0"
          offset-x="11"
          offset-y="10"
        >
          <v-icon color="natural">mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else color="natural">mdi-bell</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="fill-height">
      <v-img :src="require('@/assets/01Home.svg')">
        <v-container fluid>
          <v-row no-gutters class="pt-10">
            <v-col cols="12">
              <v-card elevation="0" color="transparent">
                <v-card-text class="text-center">
                  <v-badge
                    bordered
                    color="natural"
                    overlap
                    bottom
                    offset-x="25"
                    offset-y="25"
                  >
                    <template slot="badge">
                      <v-icon color="primary" style="font-size: 12px !important;"> mdi-pencil</v-icon>
                    </template>
                    <v-avatar
                      color="white"
                      size="97"
                      style="border-style: solid"
                      @click="$router.push({ name: 'user' })"
                    >
                      <v-img
                        :src="
                          imageProfile || require('@/assets/AvatarDefault.png')
                        "
                      ></v-img>
                    </v-avatar>
                  </v-badge>
                  <div class="pt-5 text-h4 text-center natural--text">
                    {{ role.fullname }}
                  </div>
                  <div class="pt-1 text-title-2 text-center natural--text">
                    {{ role.roleName }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
      <v-container color="transparent">
        <v-row class="mx-auto">
          <v-col cols="6">
            <MenuCard
              title="แบบฟอร์มคัดกรอง"
              filename="formmenu@2x.svg"
              @onClick="$router.push('/formlist')"
            />
          </v-col>
          <v-col cols="6">
            <MenuCard
              title="รายการบันทึกประจำวัน"
              filename="dailymenu@2x.svg"
              @onClick="$router.push('/recorddaily/waiting')"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuCard from "@/components/home/MenuCard";
import { getProfilePicture, getForm, fromLogo, user, updateUserProfile } from "@/api";
import { offlineimage } from "@/mixins/offlineimage";
import firebase from "firebase/app";

export default {
  name: "Home",
  components: {
    MenuCard,
  },
  computed: {},
  mixins: [offlineimage],
  data() {
    return {
      error: null,
      post: null,
      loading: false,
      imageProfile: null,
      role: {
        fullname: "",
      },
      numberOfdraft: 0
    };
  },
  methods: {
    updateFCMToken() {
      if (firebase.messaging.isSupported()) {
            this.$messaging
              .getToken({
                vapidKey:
                  "BN1BEc17Qr4aZ8ONCRT44IdOIORUdrijl5OxwjcEpMBllTjTPzAal2lgaRN-driCmcOpKCyyzoP0eCNQgvQFKIE",
              })
              .then((currentToken) => {
                if (currentToken) {
                  console.log(currentToken);
                  user(message=>{
                    if(message.data){
                      // console.log(message.data)
                      updateUserProfile({id: message.data.result.id, etag:  message.data.result._etag },{firebaseToken: currentToken},message=>{
                        if (message.data.code === 1) {
                          console.log('update user profile success')
                        }
                        // console.log(message)
                      },error=>{
                    console.log(error)
                  })
                    }
                  },error=>{
                    console.log(error)
                  })
                } else {
                  // Show permission request.
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
              });
          }
    },
    signOut() {
      this.$offlineStorage.set('isAuthen', false)
      this.$router.push({name: 'signin'})
    },
    async fetchData() {
      this.error = this.post = null;
      this.role = this.$offlineStorage.get("role");
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      if(!tenant) this.signOut()
      if (navigator.onLine) {
        try {
          this.loading = true;
          //get firebase token
          this.updateFCMToken()
          //1 load draft items
          let draft = this.$offlineStorage.get("draft");
          if (draft) {
            this.numberOfdraft = draft
              .filter((item) => item[0] === this.role.uuid.mobile)
              .map((item) => item[1]).length;
          }

          if (this.role.profilePicture) {
            //2 load profile
            getProfilePicture(
              {
                tenantId: tenant.tenantId,
                siteId: site.siteId,
                profilePicture: this.role.profilePicture,
              },
              (message) => {
                if (message.data.code === 1) {
                  this.imageProfile = message.data.result;
                  this.saveImage(message.data.result, this.role.profilePicture);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }

          this.loading = false;
          //3 load formlist
          let resp = await getForm({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
          });
          if (resp.data.code === 1) {
            for (let i = 0; i < resp.data.result.length; i++) {
              if (resp.data.result[i].formLogo) {
                let img = await fromLogo({
                  tenantId: tenant.tenantId,
                  siteId: site.siteId,
                  formLogo: resp.data.result[i].formLogo,
                });
                resp.data.result[i].img = img.data.result;
                this.saveImage(img.data.result, resp.data.result[i].formLogo);
              }
            }
          }
          this.$offlineStorage.set("formlist", resp.data.result);
        } finally {
          this.loading = false;
        }
      } else {
        // console.log(this.role.profilePicture)
        this.imageProfile = this.loadImage(this.role.profilePicture);
        let draft = this.$offlineStorage.get("draft");
        if (draft) {
          this.numberOfdraft = draft
            .filter((item) => item[0] === this.role.uuid.mobile)
            .map((item) => item[1]).length;
        }
        // console.log(this.imageProfile)
      }
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>
<style lang="scss" scoped>

</style>
