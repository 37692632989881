<template>
  <v-card height="208" @click="$emit('onClick')" width="164" >
    <v-card-text class="text-center mx-auto">
      <div class="pa-2">
        <!-- <v-avatar color="grey" size="80">
         <v-img :src="require(`@/assets/${filename}`)"></v-img> 
        </v-avatar> -->

         <v-img sizes="80" contain :src="require(`@/assets/${filename}`)"></v-img> 
      </div>
      <div class="text-body-large text-center font-weight-bold primary--text text--darken-4">
        {{title}}
      </div>
      <!-- <v-btn
        class="v-btn--offset mx-auto"
        width="116"
        height="40"
        color="primary"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        filename: {
            type: String,
            default: 'dailymenu@2x.svg'
        }
    },
};
</script>


<style lang="scss" scoped>
  .v-btn--offset {
    top: 25px;
    position: relative;
  }
</style>